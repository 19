
/*********************************************
 * Hijri - Gregorian Date Picker             *
 *                                           *
 * Design by ZulNs @Yogyakarta, January 2016 *
 *********************************************/

.calendar {
	/* font-family: "Times New Roman", Times, serif; */
	font-size: 14px;
	width: 308px;
	cursor: default;
	background: #fff;
	/* background: #eef;
	background: -webkit-linear-gradient(#eff,#fef,#ffe);
	background: -moz-linear-gradient(#eff,#fef,#ffe);
	background: -o-linear-gradient(#eff,#fef,#ffe);
	background: linear-gradient(#eff,#fef,#ffe); */
	box-shadow: 0 4px 8px rgba(0,0,0,.5);
	position: absolute;
	z-index: 9999;
}
.calendar .header-button,
.calendar .setting-button,
.calendar .weekday-row div,
.calendar .date-grid div {
	display: inline-block;
	text-align: center;
	height: 30px;
	line-height: 30px;
}
.calendar .weekday-row div,
.calendar .date-grid div {
	vertical-align: middle;
}
.calendar .header-row *,
.calendar .setting-button,
.calendar .date-grid .normal-date {
	cursor: pointer;
}
.calendar .header-row,
.calendar .month-field,
.calendar .year-field {
	color: #000;
	/* background: #259; */
	background: transparent;
}
.calendar .setting-row {
	color: #fff;
	background: #2b7;
}
.calendar .header-button:active,
.calendar .header-button.active:active {
	color: #259;
	background: #ccf;
}
.calendar .header-button.active {
	color: #fff;
	background: #47b;
}
.calendar .setting-button:active,
.calendar .setting-button.active:active {
	color: #2b7;
	background: #cfc;
}
.calendar .setting-button.active {
	color: #fff;
	background: #4d9;
}
.calendar .setting-button.disabled {
	color: #777;
	background: #1a6;
	pointer-events: none;
}
.calendar .month-field,
.calendar .year-field {
	border: 0;
	outline: 0;
	padding: 0;
	/* font-family: "Times New Roman", Times, serif; */
	font-size: 14px;
	text-align: center;
	-moz-text-align-last: center;
	     text-align-last: center;
}
.calendar .month-field {
	width: 96px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	overflow: hidden;
}
.calendar .month-field::-ms-expand {
	display: none;
}
.calendar .year-field {
	width: 40px;
}
.calendar .header-button {
	width: 16px;
}
.calendar .setting-button {
	width: 40px;
}
.calendar .weekday-row div,
.calendar .date-grid .excluded-date {
	width: 40px;
}
.calendar .date-grid .normal-date {
	width: 30px;
	height: 24px;
	line-height: 24px;
	margin: 3px 5px;
}
.calendar .normal-date {
	color: #555;
}
.calendar .friday-date {
	color: #292;
}
.calendar .sunday-date {
	color: #c22;
}
.calendar .date-grid .excluded-date {
	font-size: .9em;
	color: #aaa;
}
.calendar .date-grid .normal-date:active {
	color: #fff;
	background: #939;
	box-shadow: 0 0 1px #000;
}
.calendar .date-grid .current-date,
.calendar .date-grid .specialz-date,
.calendar .date-grid .selected-date {
	box-shadow: 0 1px 3px rgba(0,0,0,.5);
}
.calendar .date-grid .current-date {
	color: #fff;
	background: #f31;
}
.calendar .date-grid .specialz-date {
	color: #fff;
	background: #2b7;
}
.calendar .date-grid .selected-date {
	color: #000;
	background: #ffc30d;
}


.calendar .header-row {
	text-align: center;
}
.calendar .weekday-row {
	font-weight: bold;
	border-bottom: 1px solid #ccc;
	padding: 5px 0 2px;
}